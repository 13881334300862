<template>
  <i-dialog :name="dialogName">
    <p slot="title" class="fs-20 fw-b">
      {{ title }}
    </p>
    <el-row type="flex" align="middle" class="i-components">
      <el-col :sm="6" :xs="24" class="fs-20 pr-15 black mb-m-5">
        Название группы:
      </el-col>
      <el-col :sm="18" :xs="24">
        <el-input v-model="name" autocomplete="nope" />
      </el-col>
    </el-row>
    <el-row class="i-components mt-30">
      <el-col
        :sm="6"
        :xs="24"
        class="fs-20 mt-20 pr-15 black pr-m-0 mb-m-5 mt-m-0"
      >
        Описание группы:
      </el-col>
      <el-col :sm="18" :xs="24" class="fs-20 black">
        <el-input
          v-model="description"
          :autosize="{ minRows: 8, maxRows: 6 }"
          type="textarea"
        />
      </el-col>
    </el-row>
    <el-row class="mt-50 ta-r">
      <i-button
        :text="id ? 'Сохранить' : 'Добавить'"
        :loading="loading"
        :disabled="loading"
        width="230"
        @click="onClick"
      />
    </el-row>
  </i-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'

export default {
  name: 'EditUserGroupModal',
  components: { IDialog, IButton },
  data() {
    return {
      dialogName: 'edit-user-group-modal',
      id: 0,
      name: '',
      description: ''
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val?.id) {
          this.id = val.id
          this.name = val.name
          this.description = val.description
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getDialog: 'popups/getDialog',
      getLoading: 'biddersGroups/getLoading'
    }),
    loading() {
      return this.getLoading()
    },
    item() {
      return this.getDialog(this.dialogName).data || {}
    },
    title() {
      const start = this.id ? 'Редактирование' : 'Добавление новой'
      return `${start} группы пользователей`
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    ...mapActions('biddersGroups', ['CREATE', 'UPDATE']),
    onClick() {
      if (!this.name) {
        this.errHandler('Необходимо заполнить название группы пользователей')
      } else {
        const { id, name, description, dialogName } = this
        const data = { name, description }

        if (id) {
          if (this.item.bidders)
            data.bidders_ids = this.item.bidders.flatMap(b => b.id)
          this.UPDATE({ id, data }).then(res => {
            if (res !== 'error') {
              this.$message.success('Группа успешно обновлена')
              this.setDialog({ name: dialogName })
            }
          })
        } else {
          this.CREATE(data).then(res => {
            if (res !== 'error') {
              this.$message.success('Группа успешно создана')
              this.setDialog({ name: dialogName })
            }
          })
        }
      }
    }
  }
}
</script>
