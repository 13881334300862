<template>
  <el-table
    v-loading="loading"
    :data="getList"
    empty-text="Нет данных"
    stripe
    :key="updateTable"
    :class="['table-user-groups mt-20', { 'at-empty': !getList.length }]"
    style="width: 100%"
  >
    <el-table-column
      v-for="el in tableFields"
      :key="el.prop"
      :label="el.label"
      :prop="el.prop"
      :width="el.width + 'px'"
      :min-width="el.minWidth ? `${el.minWidth}px` : ''"
    >
      <template slot="header">
        {{ el.label }}
        <TableCell :data="el.span" />
      </template>
      <template slot-scope="props">
        <TableCell :data="props.row[el.prop]" :prop-key="el.prop" />
      </template>
    </el-table-column>
    <el-table-column width="70px">
      <template slot-scope="scope">
        <el-row type="flex" align="middle">
          <el-col
            class="user-edit-icon"
            @click.native="onUsersEdit(scope.$index, scope.row)"
          />
          <el-col
            class="edit-icon"
            @click.native="onEdit(scope.$index, scope.row)"
          />
        </el-row>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import TableCell from '@/components/Ui/TableCell'
import { getTableFields } from '../constants'

export default {
  name: 'BiddersGroupsTable',
  components: { TableCell },
  computed: {
    ...mapGetters('biddersGroups', ['getList', 'getLoading']),
    loading() {
      return this.getLoading()
    },
    tableFields() {
      if (this.isMobile) return getTableFields('Mobile')
      else return getTableFields()
    },
    updateTable() {
      if (this.isMobile) return 'mobile'
      else if (this.isTablet) return 'tablet'
      else return 'desktop'
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    onUsersEdit(index, row) {
      this.setDialog({
        name: 'add-bidders-to-group-modal',
        visible: true,
        data: row
      })
    },
    onEdit(index, row) {
      this.setDialog({
        name: 'edit-user-group-modal',
        visible: true,
        data: row
      })
    }
  }
}
</script>

<style lang="sass">
.table-user-groups
  &.at-empty:before
    background-color: unset
  .name, .count
    font-weight: bold
</style>
