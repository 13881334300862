<template>
  <el-row
    type="flex"
    align="middle"
    justify="space-between"
    class="bidder-filters i-filters"
  >
    <div class="title-with-filter w-100">
      <el-col class="fs-25 t-greenDark fw-b">
        <span>{{ title }}</span>
      </el-col>
      <el-col :xs="20" v-if="showStatus" class="filter-status">
        <el-select
          clearable
          v-model="is_in_groups"
          placeholder="Выберите из списка"
        >
          <el-option
            v-for="item in filterStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
    </div>
    <div class="search-block">
      <search-input :value="search" :showTitle="false" @change="onChange" />
    </div>
  </el-row>
</template>

<script>
import SearchInput from '@/components/Ui/SearchInput'
import { filterStatusOptions } from './constants'

export default {
  name: 'BiddersFilter',
  components: { SearchInput },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      filterStatusOptions
    }
  },
  computed: {
    is_in_groups: {
      get() {
        return this.filters.is_in_groups
      },
      set(val) {
        this.onChange('is_in_groups', val)
      }
    },
    search: {
      get() {
        return this.filters.any
      }
    }
  },
  methods: {
    onChange(key, val = undefined) {
      this.$emit('change', key, val)
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.bidder-filters
  @include to(1240px)
    flex-wrap: wrap
  .title-with-filter
    display: flex
    align-items: center
  .search-block
    width: auto
    @include to(1240px)
      width: 100%
  .el-input__inner
    height: 30px !important
    color: $textGreyDarkColor2 !important
  .el-input__icon
    line-height: 30px !important
  .el-col
    margin-bottom: 15px
  .filter-status
    .el-input
      margin-bottom: 0
    span
      color: $textGreyDarkColor2
      @include to(768px)
        margin-bottom: 10px
</style>
