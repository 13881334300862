<template>
  <page-wrapper title="СПИСОК ГРУПП ПОЛЬЗОВАТЕЛЕЙ">
    <fast-link-menu />
    <filters
      :filters="filters"
      :is-status="false"
      @change="onChange"
      @add="openAddModal"
    />
    <bidders-groups-table />
    <i-pagination :params="getPagination" @changed="onChangePage" />
    <edit-user-group-modal />
    <add-bidders-to-group-modal />
  </page-wrapper>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import Filters from '@/components/TablesFormsFilters/Filters'
import IPagination from '@/components/Ui/Pagination'
import FastLinkMenu from '@/components/Reusable/FastLinkMenu'
import BiddersGroupsTable from './components/BiddersGroupsTable'
import EditUserGroupModal from './components/EditUserGroupModal'
import AddBiddersToGroupModal from './components/AddBiddersToGroupModal'

export default {
  name: 'BiddersGroups',
  components: {
    FastLinkMenu,
    AddBiddersToGroupModal,
    EditUserGroupModal,
    PageWrapper,
    Filters,
    IPagination,
    BiddersGroupsTable
  },
  data() {
    return {
      filters: {
        'per-page': 20,
        any: ''
      },
      page: 1
    }
  },
  computed: {
    ...mapGetters('biddersGroups', ['getPagination'])
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.name === 'BiddersGroups' && this.isBidder) {
          return this.goToTender()
        }
      }
    }
  },
  created() {
    this.getBiddersGroups()
  },
  methods: {
    ...mapActions('biddersGroups', ['FETCH']),
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    getBiddersGroups() {
      return this.FETCH({ ...this.filters, page: this.page })
    },
    onChange(key, val) {
      this.filters[key] = val
      this.getBiddersGroups()
    },
    onChangePage(page) {
      this.page = page
      this.getBiddersGroups()
    },
    openAddModal() {
      this.setDialog({
        name: 'edit-user-group-modal',
        visible: true
      })
    },
    goToTender() {
      this.errHandler('Ошибка доступа')
      this.$router.push({ name: 'Tenders' })
    }
  }
}
</script>
