<template>
  <el-row type="flex" :gutter="20" class="h-table users-tables">
    <el-col class="of-hidden h-table">
      <bidders-filter
        :showStatus="showStatus"
        :filters="filters"
        :title="config.topTitle"
        @change="onChangeFilter"
      />
      <component
        :is="component"
        :participants="all"
        :loading="loading"
        @change="toAddList"
      />
      <el-row type="flex" align="middle" justify="space-between">
        <i-pagination :params="pagination" @changed="onChangePage" />
        <el-col class="ta-r">
          <i-button
            :text="config.addButtonTitle"
            width="250"
            fs="15"
            custom-class="small-btn"
            :loading="loading"
            :disabled="!all.length || loading"
            @click="onAdd"
          />
        </el-col>
      </el-row>
    </el-col>
    <el-col class="of-hidden h-table">
      <bidders-filter
        :filters="localFilter"
        :title="config.bottomTitle"
        @change="onChangeLocalFilter"
      />
      <el-collapse-transition>
        <component
          :is="component"
          :participants="filtredList"
          :loading="loading"
          secondary
          @change="toRemoveList"
        />
      </el-collapse-transition>
      <el-col class="ta-r">
        <i-button
          :text="config.removeButtonTitle"
          type="info"
          width="250"
          fs="15"
          :loading="loading"
          :disabled="!filtredList.length || loading"
          custom-class="small-btn"
          @click="onRemove"
        />
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import IButton from '@/components/Ui/Button'
import IPagination from '@/components/Ui/Pagination'
import BiddersFilter from '@/components/TablesFormsFilters/BiddersFilter'
import BiddersTable from '@/components/TablesFormsFilters/BiddersTable'
import BiddersGroupTable from '@/components/TablesFormsFilters/BiddersGroupTable'

export default {
  name: 'UsersTablesModalBody',
  components: {
    BiddersFilter,
    BiddersTable,
    BiddersGroupTable,
    IButton,
    IPagination
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        topTitle: 'Исполнители',
        addButtonTitle: 'Добавить',
        bottomTitle: 'Состоят',
        removeButtonTitle: 'Удалить'
      })
    },
    chosen: {
      type: Array,
      default: () => []
    },
    all: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => ({})
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    component: {
      type: String,
      default: 'bidders-table'
    }
  },
  data() {
    return {
      localFilter: {
        any: ''
      },
      activeToAdd: [],
      activeToRemove: []
    }
  },
  computed: {
    filtredList() {
      const { any } = this.localFilter
      return any
        ? this.chosen.filter(ch => JSON.stringify(ch).includes(any))
        : this.chosen
    }
  },
  methods: {
    onAdd() {
      this.$emit('add', this.activeToAdd)
    },
    onRemove() {
      this.$emit('remove', this.activeToRemove)
    },
    onChangePage(page) {
      this.$emit('page', page)
    },
    toAddList(actives) {
      this.activeToAdd = actives
    },
    toRemoveList(actives) {
      this.activeToRemove = actives
    },
    onChangeFilter(key, val) {
      this.$emit('filter', key, val)
    },
    onChangeLocalFilter(key, val) {
      this.localFilter[key] = val
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.users-tables-modal
  .el-dialog__body
    padding: 20px
    height: calc(100% - 140px)
    @include to(1024px)
      padding: 15px 10px
    @include to(768px)
      overflow: auto
    .users-tables
      @include to(768px)
        flex-direction: column
    .h-table
      height: 100%
      @include to(768px)
        height: auto
  .el-pagination
    margin: 0
</style>
