export const getTableFields = (type = '') => {
  return tables[`ugFields${type}`]
}

const tables = {
  ugFields: [
    { prop: 'id', label: 'ID', width: 80 },
    { prop: 'created_at', label: 'ДАТА СОЗДАНИЯ', width: 130 },
    { prop: 'name', label: 'НАЗВАНИЕ ГРУППЫ' },
    { prop: 'description', label: 'ОПИСАНИЕ' },
    { prop: 'bidders_count', label: 'КОЛ-ВО', span: 'УЧАСТНИКОВ' }
  ],
  ugFieldsMobile: [
    { prop: 'id', label: 'ID', width: 80 },
    { prop: 'name', label: 'НАЗВАНИЕ ГРУППЫ' },
    { prop: 'bidders_count', label: 'КОЛ-ВО', span: 'УЧАСТНИКОВ' }
  ]
}
