<template>
  <i-dialog :name="name" fullscreen class="users-tables-modal">
    <el-row slot="title" class="w-100">
      Управление группой "{{ item.name }}"
    </el-row>
    <users-tables-modal-body
      :config="config"
      :chosen="inGroup"
      :all="users"
      :pagination="pagination"
      :loading="loading"
      :filters="filters"
      showStatus
      @add="onAdd"
      @remove="onRemove"
      @page="onChangePage"
      @filter="onChangeFilter"
    />
  </i-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IDialog from '@/components/Ui/Dialog'
import UsersTablesModalBody from '@/components/TablesFormsFilters/UsersTablesModalBody'

export default {
  name: 'AddBiddersToGroupModal',
  components: { UsersTablesModalBody, IDialog },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val.id) this.getApprovedUsers()
      }
    }
  },
  data() {
    return {
      name: 'add-bidders-to-group-modal',
      config: {
        topTitle: 'Исполнители',
        addButtonTitle: 'Добавить в группу',
        bottomTitle: 'Состоят в группе',
        removeButtonTitle: 'Удалить из группы'
      },
      filters: {
        is_in_groups: undefined,
        any: ''
      },
      page: 1
    }
  },
  computed: {
    ...mapGetters({
      getDialog: 'popups/getDialog',
      getById: 'biddersGroups/getById',
      pagination: 'approvedUsers/getPagination',
      users: 'approvedUsers/getList',
      getLoading: 'approvedUsers/getLoading',
      getLoadingGroups: 'biddersGroups/getLoading'
    }),
    item() {
      return this.getDialog(this.name).data || {}
    },
    inGroup() {
      return this.item.id ? this.getById(this.item.id).bidders : []
    },
    loading() {
      return this.getLoading() || this.getLoadingGroups()
    }
  },
  methods: {
    ...mapActions({
      FETCH: 'approvedUsers/FETCH',
      UPDATE: 'biddersGroups/UPDATE'
    }),
    getApprovedUsers() {
      this.FETCH({
        ...this.filters,
        page: this.page,
        not_in_group: this.item.id
      })
    },
    onChangeFilter(key, val) {
      this.filters[key] = val
      this.getApprovedUsers()
    },
    onChangePage(page) {
      this.page = page
      this.getApprovedUsers()
    },
    onAdd(users) {
      this.onUpdate([...this.inGroup, ...users].flatMap(u => u.id))
    },
    onRemove(users) {
      this.onUpdate(
        this.inGroup
          .filter(cur => !users.find(u => u.id === cur.id))
          .flatMap(u => u.id)
      )
    },
    onUpdate(bidders_ids) {
      this.UPDATE({ id: this.item.id, data: { bidders_ids } }).then(res => {
        if (res !== 'error') {
          this.getApprovedUsers()
        }
      })
    }
  }
}
</script>

<style lang="sass">
.add-bidders-to-group-modal.is-fullscreen
  overflow: hidden
  max-width: unset
</style>
